import './../../node_modules/bootstrap/dist/css/bootstrap-reboot.min.css';
import './../scss/style.scss';
import './sound';
import * as canvasFrame from './canvasFrameAnimations';
import * as data from './../config.json';

//pages and all backgrounds
let pageGreetings = document.querySelector('.greetings');
let pageContent = document.querySelector('.content');
let pageFinal = document.querySelector('.final');
let backgrounds = document.querySelectorAll('.backgrounds img, .backgrounds canvas');
let backgroundsDiv = document.querySelector('.backgrounds');
let btnSoundTop = document.querySelector('.sound.top-btn');
let btnPlay = document.querySelector('#btn-play');

//pageContent items
let pageContentTexts = pageContent.querySelectorAll('h2, p');
let pageContentPedestals = pageContent.querySelector('.pedestals');
let pageContentBtnGo = pageContent.querySelector('.btn-go');
let pageContentPigs = pageContent.querySelectorAll('.game-pig-animate');
let pageContentPrizes = pageContent.querySelectorAll('.game-prize');
let pageContentClicksCounter = pageContent.querySelector('#counter');
let pageContentClicksTxt = pageContent.querySelector('#counter-text');
let pageContentPrizeCaption = pageContent.querySelectorAll('.game-pedestall-caption');
let pageContentPrizeDescription = pageContent.querySelectorAll('.game-pedestall-description');

//pageFinal items
let pageFinalPrizesItems = pageFinal.querySelectorAll('.prizes-item');
let pageFinalCaption = pageFinal.querySelector('.final-caption');
let pageFinalBtnGetPrizes = pageFinal.querySelector('.btn-get-prizes');

//hidden items classes with css 'translate' option 
const hideTranslateClasses = ['hide-left', 'hide-bottom', 'hide-right', 'hide-top'];

let prizesArrCopy = data.prizes.slice();
let clicksCounter = data.prizes.length;

//ANIMATION
let pigs = pageContent.querySelectorAll('img.game-pig');
let pedestalls = pageContent.querySelectorAll('img.game-pedestall');
let flagLeft = document.querySelector('img.flag-left');
let flagRight = document.querySelector('img.flag-right');
let canvasFlags = document.querySelectorAll('canvas.flag-left, canvas.flag-right');
let greetingsPig = pageGreetings.querySelector('img.pig');
let contentDancingPigs = pageContent.querySelectorAll('img.game-pig-dancing');
let contentDancingPigsCanvas = pageContent.querySelectorAll('canvas.game-pig-dancing-canvas');
let pigHoverImg = pageContent.querySelectorAll('img.game-pig-hover');
let pigHoverImgMask = pageContent.querySelectorAll('img.game-pig-hover-mask');

//первичная отрисовка: статичных свинок
pigs.forEach((pig, index) => {
    canvasFrame.drawImage(`#pig${index}`, pig, data.pigSprites[index], 5, 5, 150, 165, 144, 158, 99, 115);
});
//свинки с приветствия
canvasFrame.drawImage(`#togoldpig0`, greetingsPig, './img/pig-to-gold-sprite.png', 1, 1, 150, 165, 146, 163, 217, 261)
//пьедесталов
pedestalls.forEach((pedestall, index)=> {
    canvasFrame.drawImage(`#pedestall${index}`, pedestall, './img/pedestall-sprite.png', 1, 1, 183, 415, 184, 415, 138, 312);
});
//флагов на фоне
canvasFrame.drawImage('#flag0', flagLeft, './img/flag-left-sprite.png', 1, 1, 146, 513, 138, 512, 80, 382);
canvasFrame.drawImage('#flag1', flagRight, './img/flag-right-sprite.png', 1, 1, 146, 513, 145, 512, 85, 382);

//мгновенный запуск анимаций флагов
flag0Animate();
flag1Animate();
function flag0Animate() {
    canvasFrame.animate(60, '#flag0', flagLeft, './img/flag-left-sprite.png', 10, 2, 146, 513, 138, 512, 80, 382);
    setTimeout(() => {
        canvasFrame.animateReverse(40, '#flag0', flagLeft, './img/flag-left-sprite.png', 10, 2, 146, 513, 138, 512, 80, 382, 10);
    }, 1500)
}
function flag1Animate() {
    canvasFrame.animate(70, '#flag1', flagRight, './img/flag-right-sprite.png', 10, 2, 146, 513, 145, 512, 85, 382);
    setTimeout(() => {
        canvasFrame.animateReverse(65, '#flag1', flagRight, './img/flag-right-sprite.png', 10, 2, 146, 513, 145, 512, 85, 382, 10);
    }, 1440)
}
setInterval(() => {
    flag0Animate();
}, 2000)
setInterval(() => {
    flag1Animate();
}, 2500)


//content page pigs dancing
dancingPig(0, 35, 2300);
dancingPig(1, 45, 2800);
dancingPig(2, 32, 2000);
dancingPig(3, 40, 2500);
dancingPig(4, 48, 3000);
dancingPig(5, 45, 2800);
dancingPig(6, 42, 2620);

function dancingPig(index, time, intervalTime) {
    canvasFrame.animate(time, `#pig-dancing${index}`, contentDancingPigs[index], data.pigDancingSprites[index], 10, 6, 145, 173, 145, 163, 99, 115);
    startInterval(index, time, intervalTime);
    function startInterval(index, time, intervalTime) {
        setInterval(() => {
            canvasFrame.animate(time, `#pig-dancing${index}`, contentDancingPigs[index], data.pigDancingSprites[index], 10, 6, 145, 173, 145, 163, 99, 115);
        }, intervalTime)
    }
}

//greetings page pig animation
setTimeout(() => {
    canvasFrame.animate(37, '#togoldpig0', greetingsPig, './img/pig-to-gold-sprite.png', 5, 5, 150, 165, 146, 163, 217, 261);
}, 3000)

//start the game
btnPlay.addEventListener('click', () => goToGamePage())
// setTimeout(() => { goToGamePage() }, 1);

//game logic
pageContentPigs.forEach(pig => {
    let id = Number(pig.id.match(/\d+/));

    pig.addEventListener('click', () => {
        let prize = getRandomPrize();

        //start pig animation
        canvasFrame.animate(35, `#pig${id}`, pigs[id], data.pigSprites[id], 5, 5, 150, 165, 146, 160, 99, 115);
        //start pedestall animation
        canvasFrame.animate(30, `#pedestall${id}`, pedestalls[id], './img/pedestall-sprite.png', 4, 5, 183, 415, 184, 415, 138, 312);
        
        clicksCounter -= 1;
        setTimeout(()=>{
            pageContentPrizes[id].classList.remove('hide-d-none');
            pageContentPrizes[id].src = prize.img;
            classMng.add([pigHoverImgMask[id], pigHoverImg[id], contentDancingPigsCanvas[id]], ['hide-d-none']);
        }, 250)
        
        if (clicksCounter === 1) { pageContentClicksTxt.textContent = 'касание'; }
        if (clicksCounter === 0) { pageContentClicksTxt.textContent = 'касаний'; }
        pageContentClicksCounter.textContent = clicksCounter;
        pageContentPrizeCaption[id].textContent = prize.caption;
        pageContentPrizeDescription[id].textContent = prize.description;
        pig.style.pointerEvents = 'none';

        setTimeout(() => {
            classMng.remove([pageContentPrizeCaption[id], pageContentPrizeDescription[id]], ['hide-opacity']);
        }, 600);

        if (clicksCounter === 0) {
            setTimeout(() => {
                goToFinalPage();
            }, 1400);
        }
    })
})

// Navigation and helpers

//get random element from prizes array
function getRandomPrize() {
    let randomIndex = randomInteger(0, prizesArrCopy.length - 1);
    let excludebleArrItemId = prizesArrCopy[randomIndex].id;
    let temporaryArr = [];
    let result = prizesArrCopy[randomIndex];

    function randomInteger(min, max) {
        let rand = min + Math.random() * (max + 1 - min);
        return Math.floor(rand);
    }
    for (let item of prizesArrCopy) {
        if (item.id !== excludebleArrItemId) { temporaryArr.push(item); }
    }
    prizesArrCopy = temporaryArr;
    return result;
}

//navigate functions
function goToGamePage() {
    classMng.add([pageGreetings], ['hide-top']);
    classMng.remove(backgrounds, hideTranslateClasses);
    classMng.add([backgrounds[0]], ['full-size']);
    classMng.remove([pageContent, btnSoundTop], ['hide-d-none']);
    setTimeout(() => {
        classMng.remove([pageContentPedestals, pageContentBtnGo], ['hide-bottom']);
        classMng.remove(pageContentTexts, hideTranslateClasses);
        classMng.remove([btnSoundTop], ['hide-opacity']);
    }, 250)
};

function goToFinalPage() {
    classMng.add([backgroundsDiv], ['blur']);
    classMng.add([pageContent], ['hide-d-none']);
    classMng.remove([pageFinal], ['hide-d-none']);

    canvasFlags[0].setAttribute('denyanimation', true);
    canvasFlags[1].setAttribute('denyanimation', true);

    setTimeout(() => {
        classMng.remove(pageFinalPrizesItems, ['hide-scale']);
        classMng.remove([pageFinalCaption, pageFinalBtnGetPrizes], hideTranslateClasses);
    }, 150)
};

//switching multiple classes on multiple elements at the same time
let classMng = {
    remove: function(itemsArr, classesArr) {
        itemsArr.forEach(i => i.classList.remove(...classesArr));
    },
    add: function(itemsArr, classesArr) {
        itemsArr.forEach(i => i.classList.add(...classesArr));
    },
}
