export { animate, drawImage, animateReverse };

/*
    function animate - анимация спрайта от первого кадра до последнего;
    function animateReverse - анимация спрайта от последнего кадра до первого;
    function drawImage - отрисовка в canvas одного кадра из выбранного спрайта;

    *Если на canvas задать свойство 'denyanimation' c любым содержимым,
    анимация данного canvas полностью завершается с остановкой на текущем кадре.
*/

// 2D Animation method
function animate(
    //Входные параметры:
    speed,                      // скорость одного кадра (тысячные от 1 сек),                                               Integer 
    canvas,                     // css селектор canvas'а, в котором будет анимация,                                         String
    imgObj,                     // объект img со спрайтом из которого берется кадр для первичной отрисовки,                 Object 
    spritePath,                 // конечный путь файла спрайта, по которому браузер его находит после сборки,               String
    columnsCount,               // кол-во колонок в спрайте,                                                                Integer
    rowsCount,                  // кол-во строк в спрайте,                                                                  Integer
    columnWidth,                // ширина одной колонки = (ширина картинки / кол-во колонок),                               Integer
    lineHeight,                 // высота одной строки = (высота картинки / кол-во строк),                                  Integer
    oneSpriteItemWidth,         // ширина одного элемента на кадре(без пустых пикселей),                                    Integer
    oneSpriteItemHeight,        // высота одного элемента на кадре(без пустых пикселей),                                    Integer
    fullScreenBlockWidth,       // максимальная ширина блока, в котором происходит анимация,                                Integer
    fullScreenBlockHeight,      // максимальная высота блока, в котором происходит анимация,                                Integer
    lastLineLastItemNumber      // ОПЦИОНАЛЬНО, номер кадра последней строки, с которого запустится обратная анимация (1 - первый элемент в последней строке), Integer
    ) {
    lastLineLastItemNumber = lastLineLastItemNumber ? lastLineLastItemNumber : false;
    let i = 0;

    //перебор строк
    let interval = setInterval(() => {
        let j = 1;
        let canvasDenyAnimation = document.querySelector(canvas).getAttribute('denyanimation');
        // проверяем атрибут denyanimation на canvas и отключаем в нем анимацию
        if (canvasDenyAnimation !== null) {
            clearInterval(insideInterval);
            clearInterval(interval);
            return
        }
        //перебор кадров в строке
        let insideInterval = setInterval(() => {
            //отрисовка одного кадра
            drawImage(canvas, imgObj, spritePath, i, j, columnWidth, lineHeight, oneSpriteItemWidth, oneSpriteItemHeight, fullScreenBlockWidth, fullScreenBlockHeight);
            j++;
            //остановка перебора кадров
            if(j === columnsCount + 1) {clearInterval(insideInterval);} 
        // скорость анимации одного кадра
        }, speed)
        i++;
        //остановка перебора строк
        if (i === rowsCount) { clearInterval(interval); }
    //скорость анимации одной строки
    }, speed * (columnsCount + 1));
}

//обратная анимация от последнего кадра
function animateReverse(
    speed,                     
    canvas,                    
    imgObj,                    
    spritePath,                
    columnsCount,              
    rowsCount,                 
    columnWidth,               
    lineHeight,                
    oneSpriteItemWidth,        
    oneSpriteItemHeight,       
    fullScreenBlockWidth,      
    fullScreenBlockHeight,     
    lastLineLastItemNumber
) {
    let j = lastLineLastItemNumber;
    let i = rowsCount;

    let interval = setInterval(() => {
        let insideInterval = setInterval(() => {
            let canvasDenyAnimation = document.querySelector(canvas).getAttribute('denyanimation');
            // проверяем атрибут denyanimation на canvas и отключаем в нем анимацию
            if (canvasDenyAnimation !== null) {
                clearInterval(insideInterval);
                clearInterval(interval);
                return
            }
            if(j === 0) {
                //возврат счетчика кадров к кол-ву колонок в спрайте
                j = columnsCount;
                i = i - 1;
                //все строки, кроме последней имеют количество кадров, равное кол-ву колонок
                lastLineLastItemNumber = columnsCount;
                clearInterval(insideInterval);
            }
            //остановка всей анимации на первом кадре спрайта
            if (i === 1 && j === 1) {
                clearInterval(insideInterval);
                clearInterval(interval);
            }
            if(i > 0) {
                drawImage(canvas, imgObj, spritePath, i, j, columnWidth, lineHeight, oneSpriteItemWidth, oneSpriteItemHeight, fullScreenBlockWidth, fullScreenBlockHeight);
                j = j - 1;
            }
        }, speed);
    //скорость анимации последней строки спрайта зависит от выбранного последнего кадра на последней строке
    }, speed * lastLineLastItemNumber + 1);
}

// Отрисовка одного кадра
function drawImage(
    canvasCssSelector,          // css селектор canvas'а, в котором будет анимация,                             String
    sourceImg,                  // объект img со спрайтом из которого берется кадр для первичной отрисовки,     Object
    sprite,                     // конечный путь файла спрайта, по которому браузер его находит после сборки,   String
    columnNumber,               // номер колонки в спрайте с нужным кадром, начиная с 1,                        Integer
    lineNumber,                 // номер строки в спрайте с нужным кадром, начиная с 1,                         Integer
    columnWidth,                // ширина одной колонки = (ширина картинки / кол-во колонок),                   Integer
    lineHeight,                 // высота одной строки = (высота картинки / кол-во строк),                      Integer
    oneSpriteItemWidth,         // ширина одного элемента на кадре(без пустых пикселей),                        Integer
    oneSpriteItemHeight,        // высота одного элемента на кадре(без пустых пикселей),                        Integer
    fullScreenBlockWidth,       // максимальная ширина блока, в котором происходит анимация,                    Integer
    fullScreenBlockHeight       // максимальная высота блока, в котором происходит анимация,                    Integer
    ) {

    lineNumber = lineNumber ? lineNumber - 1 : 0;
    columnNumber = columnNumber ? columnNumber - 1 : 0;
    let context = document.querySelector(canvasCssSelector).getContext('2d');
    
    //после загрузки img на странице отрисовать выбранный кадр, предварительно очистив canvas
    sourceImg.onload = function() {
        let width = sourceImg.width;
        let height = sourceImg.height;

        context.clearRect(0, 0, height, width);
        context.drawImage(sourceImg, columnWidth * lineNumber, lineHeight * columnNumber, oneSpriteItemWidth, oneSpriteItemHeight, 0, 0, fullScreenBlockWidth, fullScreenBlockHeight);
        //скрыть исходное изображение спрайта со страницы
        sourceImg.style.display = 'none';
    }
    sourceImg.src = sprite;
}
